import React, { Component } from "react";
import Card from "./components/Viz/Card/Card";

import "./assets/css/App-Dashboard.css";
import "./assets/css/DemoCustom.css";

class App extends Component {
  render() {
    return (
      <div className="App" style={{ marginTop: "20px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <Card />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
