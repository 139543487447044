import React from "react";
import styled from "styled-components";

import DropDownSelect from "../../DropDownSelect";

const Container = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  padding: 15px;
  border-bottom: 1px solid #eee;

  @media (min-width: 640px) {
    padding: 20px;
  }
`;

const Title = styled.div`
  line-height: 1.2;
  color: #777;
  font-size: 1.1rem;
  font-weight: bold;
  > div {
    font-weight: normal;
  }
  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
  @media (min-width: 992px) {
    font-size: 1.4rem;
  }
`;
const DescriptionText = styled.div`
  font-size: 0.8rem;
  padding-top: 20px;
  @media (min-width: 992px) {
    padding: 0px;
    font-size: 0.85rem;
    padding-left: 15px;
    border-left: 1px solid #0ddfee;
  }
`;

//const people = [{ name: "Johnathan Bell" }, { name: "Beth Newell" }];
export const CardHeader = ({
  className,
  title,
  undertitle,
  desc,
  selectedSalesPerson,
  changeSalesPerson,
  people,
}) => {
  const peopleOptions = Object.keys(people).map((person) => ({
    label: person,
    value: person,
  }));

  return (
    <Container>
      <div className="row">
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            <div className="col-sm-7 col-lg-12">
              <Title>
                {title} {undertitle}
              </Title>
            </div>
            <div className="col-sm-5 col-lg-12  pt-3 pt-sm-0 pt-lg-4">
              <DropDownSelect
                name="selectedSalesPerson"
                options={peopleOptions}
                selectedOption={selectedSalesPerson}
                onChangeHandler={changeSalesPerson}
                placeholder={"Select Sales Person"}
              />
            </div>
          </div>
        </div>
        <div className=" col-sm-12 col-lg-6">
          <DescriptionText>
            Quickly identify opportunity sweet spots by comparing clients'
            interest levels combined with available historical customer data.
            The height of the branch correlates with the current digital
            interest level, and each cluster represents level of engagement in
            product and solution areas (i.e. ranging from red = opted out to
            green = highly engaged install base).
          </DescriptionText>
        </div>
      </div>
    </Container>
  );
};
