import React from 'react';
import PropTypes from 'prop-types';


export default function BubbleChartBackground ({id, d}){
    return(
        <g id={id}>
            <path  d={d} />
        </g>
    )
}        


BubbleChartBackground.propTypes = {
    id: PropTypes.string.isRequired,
    d: PropTypes.string.isRequired
}