import React, { Fragment, PureComponent } from "react";
import Popover from "react-popover";

import * as d3 from "d3";
import d3Tip from "d3-tip";

const colorGroup = {
  yellow: { circle_color: "#ffe600", stroke_color: "#bc3feb" },
  red: { circle_color: "#ff5e5e", stroke_color: "#70e4f8" },
  green: { circle_color: "#53e353", stroke_color: "#ffa18d" },
  "net new": { circle_color: "#30879b", stroke_color: "#ffc90e" },
};
export default class BubblesForceLayout extends PureComponent {
  constructor(props) {
    super(props);

    this.width = 70;
    this.height = 0;
    this.center = { x: 0, y: 0 };
    this.state = {
      center: { x: 0, y: 0 },
      g: null,
      anchorEl: null,
      isVisible: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.center = nextProps.center;
    //console.log('prev',nextProps);

    if (nextProps.center.x !== this.state.center.x) {
      this.setState({ center: nextProps.center }, () => {
        // console.log('hello');
        this.renderBubbles(nextProps.data, nextProps, "FROM RECIEVE PROPS");
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.center.x !== prevProps.center.x &&
      prevProps.fullscreen === true
    ) {
      this.renderBubbles(this.props.data, this.props, "FROM DID UPDATE");
    }
  };

  onRef = (ref) => {
    this.setState({ g: d3.select(ref) }, () =>
      this.renderBubbles(this.props.data, this.props, "FROM START?")
    );
  };

  renderBubbles(data, props, message) {
    //"this" out of scope inside d3 chain functions
    let _self = this;
    console.log("Data", data);
    if (data.children.length < 10) {
      this.height = 110;
    } else if (data.children.length < 15) {
      this.height = 150;
    } else if (data.children.length < 20) {
      this.height = 180;
    } else {
      this.height = 200;
    }
    if (data.children.length >= 20) {
      this.height = 260;
    }

    //For resizing circles
    const rscale = d3
      .scaleLinear()
      .domain([
        d3.min(data.children, (d) => {
          return +d.score;
        }),
        d3.max(data.children, (d) => {
          return +d.score;
        }),
      ])
      .range([4, 17]);

    //keeps bubbles apart and
    //prevents overlap
    const collisionForce = d3
      .forceCollide(0)
      .radius(function(d) {
        return d.r + 0.6;
      })
      .iterations(2);
    const charge_force = d3.forceManyBody().strength(0);

    //To keep circles in a specified area, confines to rectangle
    let boxForce = this.boundedBox()
      .bounds([[0, 0], [this.width, this.height]])
      .size(function(d) {
        return d.r;
      });

    let nodes = data.children.map(function(d, i) {
      let r = rscale(d.score * 1);
      //let w = rscale(d.score*1)
      //let h = rscale(d.score*2)
      return {
        name: d.domain,
        data: d,
        value: d.score,
        hoverLabel: d.topic_name + " (" + d.score + ")",
        group: d.engagement_level,
        x: _self.center.x,
        y: _self.center.y,
        vx: 0,
        vy: 0,
        //height:h,
        // width:w,
        r: r,
      };
    });

    //Give a more natural final shape to the bubble cluster
    let forceX = d3.forceX(this.width / 2).strength(0.7);
    let forceY = d3.forceY(this.height / 2).strength(0.1);

    this.simulation = d3
      .forceSimulation()
      .alphaTarget(0)
      .on("tick", this.ticked.bind(this))
      .force("box", boxForce)
      .force("collide", collisionForce)
      .force("charge", charge_force)
      .force("x", forceX)
      .force("y", forceY)
      .alphaDecay(0.08); //how long it takes to settle the bubbles

    //Get cluster container so we can move it later
    const container = this.state.g;
    const bubbles = this.state.g.selectAll("circle");
    //const context = this.state.g.attr('context')

    // console.log('CONTEXT', context() );

    // Exit
    bubbles.exit().remove();

    // Enter , Add circles
    const bubblesEnter = bubbles
      .data(nodes)
      .enter()
      .append("circle")
      .classed("bubble", true)
      .call(tipCirclePack)
      .attr("id", (d, i) => "#c" + i)
      .attr("r", 0 /*d=>d.r*/)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .style("fill", (d) => {
        return colorGroup[d.group].circle_color;
      })
      .style("stroke", (d) => {
        //console.log("STROKE TIME", d);
        if (d.data.recent_update) {
          return colorGroup[d.group].stroke_color;
        }
      })
      .style("stroke-width", (d) => "3px")
      // .style("stroke", d => {  return d.group === "net new" ? d3.rgb(fillColor(d.group)).darker() :null })
      //.style("stroke-width", d=> {return d.group === "net new"? 2:null})
      .style("opacity", 1)
      .on("click", (d) => {
        if (this.props.openDetails !== undefined) this.props.openDetails(d);
      })
      .on("mouseover", function(d, i) {
        // If node has no children, show tooltip and increase the radius of the circle
        if (!d.children) {
          tipCirclePack.show(d, this);
        }
      })
      .on("mouseout", function(d, i) {
        tipCirclePack.hide(d);
      });

    /*
      let images =  bubbles.data(nodes).enter().append("image")
      .attr("xlink:href",  function(d) { return d.group === "net new" ? pearwhite: d.group === "red" ? pearred : pearbl})
      .attr("x", function(d) { return -25;})
      .attr("y", function(d) { return -25;})
      .attr('transform', "scale(0.5)")
      .attr("height", 50)
      .attr("width", 50);
     */

    this.simulation.nodes(nodes);

    let newX = this.center.x - this.width / 2;
    let newY = this.center.y - this.height / 2;

    if (props.id === "bubble1") {
      newY = newY - 20;
      newX = newX - 3;
      container.attr(
        "transform",
        "translate(" +
          newX +
          " " +
          newY +
          ") rotate(-20 " +
          this.width / 2 +
          " " +
          this.height / 2 +
          ")"
      );
    }

    if (props.id === "bubble2") {
      newX = newX + 5;
      newY = newY + 5;
      container.attr(
        "transform",
        "translate(" +
          newX +
          " " +
          newY +
          ") rotate(60 " +
          this.width / 2 +
          " " +
          this.height / 2 +
          ")"
      );
    }

    if (props.id === "bubble3") {
      if (this.height > 200) {
        newX = newX - 50;
      } else if (this.height === 200) {
        newX = newX - 40;
      } else if (this.height === 180) {
        newX = newX - 15;
      } else if (this.height === 150) {
        newX = newX - 0;
      } else {
        newX = newX - 10;
      }

      container.attr(
        "transform",
        "translate(" +
          newX +
          " " +
          newY +
          ") rotate(-80 " +
          this.width / 2 +
          " " +
          this.height / 2 +
          ")"
      );
    }

    if (props.id === "bubble4") {
      if (this.height > 200) {
        newX = newX + 5;
        newY = newY + 1;
      } else if (this.height === 200) {
        newX = newX + 30;
        newY = newY - 20;
      }

      container.attr(
        "transform",
        "translate(" +
          newX +
          " " +
          newY +
          ") rotate(60 " +
          this.width / 2 +
          " " +
          this.height / 2 +
          ")"
      );
    }

    if (props.id === "bubble5") {
      newX = newX + 20;
      // newY = newY
      container.attr(
        "transform",
        "translate(" +
          newX +
          " " +
          newY +
          ") rotate(-80 " +
          this.width / 2 +
          " " +
          this.height / 2 +
          ")"
      );
    }

    bubblesEnter
      .transition()
      .duration(900)
      .ease(d3.easeElastic)
      .delay(1500)
      .attr("r", function(d) {
        return d.r;
      })
      .on("end", () => {
        d3.selectAll(".texty")
          .transition()
          .style("opacity", 1.0);
        d3.selectAll(".listView")
          .transition()
          .style("opacity", 1.0);
      });

    // })
  }

  boundedBox() {
    var nodes, sizes;
    var bounds;
    var size = this.constant(0);

    function force() {
      var node, size;
      var xi, x0, x1, yi, y0, y1;
      var i = -1;
      while (++i < nodes.length) {
        node = nodes[i];
        size = sizes[i];

        xi = node.x + node.vx;
        x0 = bounds[0][0] - (xi - size);
        x1 = bounds[1][0] - (xi + size);
        yi = node.y + node.vy;
        y0 = bounds[0][1] - (yi - size);
        y1 = bounds[1][1] - (yi + size);
        if (x0 > 0 || x1 < 0) {
          node.x += node.vx;
          node.vx = -node.vx;
          if (node.vx < x0) {
            node.x += x0 - node.vx;
          }
          if (node.vx > x1) {
            node.x += x1 - node.vx;
          }
        }
        if (y0 > 0 || y1 < 0) {
          node.y += node.vy;
          node.vy = -node.vy;
          if (node.vy < y0) {
            node.vy += y0 - node.vy;
          }
          if (node.vy > y1) {
            node.vy += y1 - node.vy;
          }
        }
      }
    }

    force.initialize = function(_) {
      sizes = (nodes = _).map(size);
    };

    force.bounds = function(_) {
      return arguments.length ? ((bounds = _), force) : bounds;
    };

    force.size = function(_) {
      return arguments.length
        ? ((size = typeof _ === "function" ? _ : this.constant(_)), force)
        : size;
    };

    return force;
  }

  ticked() {
    //console.log(this.state.g._groups[0])
    this.state.g
      .selectAll("circle")
      .attr("cx", function(d) {
        return d.x;
      })
      .attr("cy", function(d) {
        return d.y;
      });
    /*
            this.state.g.selectAll('image')
            .attr("transform", function(d) {
              return "translate(" + d.x + "," + d.y + ") rotate(-60) scale(0.7)";
            });
      */
  }

  constant(_) {
    return function() {
      return _;
    };
  }
  handleLabelClick = (e, data) => {
    console.log("DATA", e.target.dataset.name, data);
    this.setState({ anchorEl: e.currentTarget });
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };
  render() {
    const { center } = this.state;
    console.log(this.props.data.length);
    const { id, label, position1 } = this.props;

    //Text label positioning
    let textX = center.x < position1.x ? center.x - 60 : center.x + 60;
    let textY = center.y - 20;

    if (center.x === position1.x) {
      textX = center.x + 30;
      textY = center.y - 50;
    }
    if (id === "bubble2") {
      textX = center.x + 75;
      textY = center.y + 15;
    }
    if (id === "bubble3") {
      textX = center.x - 120;
      textY = center.y - 50;
    }
    if (id === "bubble4") {
      textY = center.y + 20;
    }
    if (id === "bubble5") {
      textY = center.y + 60;
    }

    return (
      <Fragment>
        {this.isVisible && (
          <foreignObject>
            <div>boo</div>
          </foreignObject>
        )}
        <g ref={this.onRef} id={id} className="bubbles">
          {/*<rect id={id+1} width={70} height={this.height} style={ {strokeWidth: '1', fill: 'none', stroke:'#000'}}  />*/}
          {/**/}
        </g>
        <text
          className="texty"
          style={{ opacity: 0 }}
          dx={textX}
          dy={textY}
          data-name={label}
        >
          {this.props.data.children.length > 0 && label}
        </text>
        {this.props.data.children.length > 0 ? (
          <foreignObject
            x={textX}
            y={textY}
            width="60px"
            height="20px"
            style={{ opacity: 0 }}
            className="listView"
          >
            <Popover
              isOpen={this.state.isVisible}
              body={
                <div
                  style={{
                    background: "#fff",
                    padding: "20px",
                    fontSize: "12px",
                    boxShadow: "2px 12px 26px 0px rgb(47 77 105 / 20%)",
                  }}
                >
                  <div style={{ height: "300px", overflow: "auto" }}>
                    {this.props.data.children.map((child, index) => (
                      <React.Fragment key={"gra_" + index}>
                        {index === 0
                          ? Object.keys(child).map(
                              (key, index) =>
                                key !== "loom.net_new_account" &&
                                key !== "id" &&
                                key !== "recent_update" &&
                                key !== "fake_cluster" && (
                                  <span
                                    className="header"
                                    key={"column_" + index}
                                  >
                                    {key[0].toUpperCase() +
                                      key
                                        .substring(1)
                                        .replace("_", " ")
                                        .replace("level", "")}
                                  </span>
                                )
                            )
                          : null}
                        {child.campaign === label && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderBottom: "1px solid #eee",
                            }}
                            key={"frag_" + index}
                          >
                            <span className="cell" key={"childc_" + index}>
                              {child.campaign}
                            </span>
                            <span className="cell" key={"childd_" + index}>
                              <div
                                onClick={() => {
                                  if (this.props.openDetails !== undefined)
                                    this.props.openDetails(child);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#0066ff",
                                }}
                              >
                                {" "}
                                {child.domain}
                              </div>
                            </span>
                            <span
                              className="cell"
                              key={"childe_" + index}
                              style={{ textAlign: "center" }}
                            >
                              <div
                                className="dot"
                                style={{
                                  backgroundColor:
                                    colorGroup[child.engagement_level]
                                      .circle_color,
                                }}
                              />
                            </span>
                            <span className="cell" key={"childt_" + index}>
                              {child.topic_name}
                            </span>
                            <span className="cell" key={"childs_" + index}>
                              {child.score}
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              }
              onOuterAction={(e) => this.handleLabelClick(e, this.props.data)}
            >
              <div
                onClick={(e) => this.handleLabelClick(e, this.props.data)}
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                {" "}
                List view
              </div>
            </Popover>
          </foreignObject>
        ) : null}
      </Fragment>
    );
  }
}

/*BubblesForceLayout.propTypes = {
  center: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  forceStrength: PropTypes.number.isRequired,
 
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    radius: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,

  })),
}*/

// Init tooltip
const tipCirclePack = d3Tip()
  .attr("class", "d3-tip")
  .offset([-10, 0])
  .html(function(d) {
    return (
      "<strong>" +
      d.name +
      ": </strong> <span style='color:#eee'>" +
      d.hoverLabel +
      "</span>"
    );
  });
const tipDataList = d3Tip()
  .attr("class", "d3-tiptable")
  .offset([-10, 0])
  .html(function(d) {
    return (
      "<strong>" +
      d.name +
      ": </strong> <span style='color:#eee'>" +
      d.hoverLabel +
      "</span>"
    );
  });
