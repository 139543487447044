import React, { Component, Fragment } from "react";

//App Components
import { CardHeader } from "./CardHeader";
import Tree from "./Tree/Tree";
//import CardLoader from "./CardLoader";
import { LoadingIndicator } from "./LoadingIndicator";
import CardFooter from "./CardFooter";
import DetailsPanel from "./DetailsPanel";
import ActionPanel from "./ActionPanel";

//3rd Party
import styled, { css } from "styled-components";

import _ from "lodash";

import { Transition, animated } from "react-spring";

import ContainerDimensions from "react-container-dimensions";
import "gemini-scrollbar/gemini-scrollbar.css";

// import ContentLoader from 'react-content-loader';

//Utils
import { getTableData } from "../../../csvUtils";
//import {Loader} from '../../../components-dashboard/Loader'; ///will eventaully be completly replaced by ContentLoader from 'react-content-loader';

//Icons
import "../../../assets/webfonts/dripicons/webfont.css";

const dataURL = process.env.PUBLIC_URL + "/csv/tree.csv";
const dataURL2 = process.env.PUBLIC_URL + "/csv/trees2.csv";
const dataURL3 = process.env.PUBLIC_URL + "/csv/trees3.csv";

const Container = styled.div`
  background: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
`;

const SVGContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: auto;

  @media (min-width: 640px) {
    height: ${(props) => (props.fullscreen ? "80vh" : "auto")};
    ${(props) =>
      props.fullscreen &&
      css`
        overflow-y: scroll;
      `};
  }
`;

//Not used yet ... problematic re render of D3 viz whne doin fullscreen that casuees paretn re render
/*const FullScreenContainer =  styled.div`
    background: #fff;
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
    z-index:9999;
`;*/

const FullScreenStyles = {
  background: "#fff",
  position: "fixed",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  height: "100%",
  width: "100%",
  zIndex: "9",
  transform: "translateY(0)",
};
const people = {
  "Johnathan Bell": { dataURL: dataURL },
  "Beth Newell": { dataURL: dataURL2 },
  "Laurie Mendoza": { dataURL: dataURL3 },
};
export default class CardContainer extends Component {
  constructor(props) {
    super(props);
    //console.log('packdata', createPackNodes(data1), createNodes(data2));
    this.state = {
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      width: 510,
      height: 510,
      details: null,
      isLoading: true,
      fullscreen: false,
      showDetails: false,
      showActionPanel: false,
      selectedSalesPerson: "Johnathan Bell",
    };
  }

  componentDidMount = () => {
    this.setState(
      (state) => ({ isLoading: true }),
      () => {
        getTableData(dataURL, this.onDataLoaded);
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedSalesPerson } = this.state;

    if (prevState.selectedSalesPerson !== selectedSalesPerson) {
      this.setState(
        (state) => ({ isLoading: true }),
        () => {
          setTimeout(() => {
            getTableData(
              people[selectedSalesPerson].dataURL,
              this.onDataLoaded
            );
          }, 1000);
        }
      );
    }
  };

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log("componentDidCatch", error, info);
  }

  onDataLoaded = (tableData) => {
    console.log("data", tableData);
    if (tableData.columns[0].accessor === "no_data") {
      this.setState((state) => {
        return { error: "No Data" };
      });
    }

    const dataRows = tableData.tableRows;
    const data1 = { name: "root", children: [] },
      data2 = { name: "root", children: [] },
      data3 = { name: "root", children: [] },
      data4 = { name: "root", children: [] },
      data5 = { name: "root", children: [] };

    let id = 1;
    _.each(dataRows, (data) => {
      data.id = id++;
      switch (data.fake_cluster) {
        case "1":
          data1.children.push(data);
          break;
        case "2":
          data2.children.push(data);
          break;
        case "3":
          data3.children.push(data);
          break;
        case "4":
          data4.children.push(data);
          break;
        case "5":
          data5.children.push(data);
          break;

        default:
          console.log("Error, No Position");
      }
    });

    this.setState(
      {
        ...this.state,
        data1: data1,
        data2: data2,
        data3: data3,
        data4: data4,
        data5: data5,
        allData: dataRows,
        yAxisLabel: "Interest and Priority",
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  openDetails = (d) => {
    const showDetails = this.state;
    this.setState((prevState) => {
      if (showDetails === true) {
        return {
          details: d.data ? d.data : d,
        };
      } else {
        return { showDetails: true, details: d.data ? d.data : d };
      }
    });
  };

  changeSalesPerson = (data) => {
    this.setState((prevState) => {
      return { selectedSalesPerson: data.value };
    });
  };
  closeDetails = (e) => {
    this.setState((prevState) => {
      return { showDetails: false };
    });
  };

  closeActionPanel = (e) => {
    this.setState((prevState) => {
      return { showActionPanel: false };
    });
  };

  openActionPanel = (e, details) => {
    this.setState((prevState) => {
      return { showActionPanel: !this.state.showActionPanel };
    });
  };

  changeSelected = (details) => {
    this.setState({ details: details });
  };

  toggleFullscreen = (e) => {
    //!! Currently using a straight DOM access and altering css directly as opposed to a prop change that causes a re render

    this.setState((prevState) => ({ fullscreen: !prevState.fullscreen }));

    let body = document.getElementsByTagName("body")[0];
    let card = this.CardRef;
    //    const oldstyle = card.style.cssText;

    //    if(oldstyle === ''){
    if (!this.state.fullscreen) {
      body.style.overflow = "hidden"; //prevent page scroll behind fullscreen layer
      Object.assign(card.style, FullScreenStyles);

      let domRect = card.getBoundingClientRect();
      console.log("fullscreen", domRect);
    } else {
      body.style.overflow = "hidden auto";
      card.style.cssText = "";
    }
  };

  render() {
    const {
      isLoading,
      details,

      showActionPanel,
      showDetails,
      fullscreen,
      selectedSalesPerson,
    } = this.state;

    //if (isLoading === true) return <CardLoader />;

    const headerDesc =
      "Quickly identify opportunity sweet spots by comparing clients' interest levels combined with available historical data.  The height of the branch correlates with the current digital interest level, and each cluster represents level of engagement in product and solution areas (i.e. ranging from green dots = highly engaged key customer, red dots = opted out to gray rings = net new leads ).";

    //const ContainerComponent = fullscreen === true ?  FullScreenContainer :   Container;
    const ContainerComponent = Container;

    return (
      <ContainerComponent
        id="heynow"
        innerRef={(x) => {
          this.CardRef = x;
        }}
      >
        <ContainerDimensions>
          {({ top, width, height }) => (
            <Fragment>
              <CardHeader
                title={selectedSalesPerson + `, Account Manager`}
                undertitle={
                  <div>
                    untapped potential by the numbers{" "}
                    <strong>right now </strong>
                    in your territory
                  </div>
                }
                desc={headerDesc}
                changeSalesPerson={this.changeSalesPerson}
                selectedSalesPerson={selectedSalesPerson}
                people={people}
              />
              <SVGContainer fullscreen={fullscreen}>
                {isLoading === true ? (
                  <LoadingIndicator color={"#333"} />
                ) : (
                  <Tree {...this.state} openDetails={this.openDetails} />
                )}
                <Transition
                  native
                  from={{ height: "133", opacity: "0" }}
                  enter={{ height: "33", opacity: "1" }}
                  leave={{ height: "133", opacity: "0" }}
                >
                  {showDetails === true &&
                    (({ height, opacity }) => {
                      //console.log('trans', opacity);
                      return (
                        <animated.div
                          style={{
                            opacity: opacity.interpolate((opacity) => opacity),
                            transform: height.interpolate(
                              (height) => `translate3d(0,${height}px,0)`
                            ),
                            transition: "transform .05s ease",
                          }}
                        >
                          <DetailsPanel
                            data={this.state["data" + details.fake_cluster]}
                            currentSelected={details}
                            closeSelf={this.closeDetails}
                            openActionPanel={this.openActionPanel}
                            fullscreen={fullscreen}
                            changeSelected={this.changeSelected}
                          />
                        </animated.div>
                      );
                    })}
                </Transition>
              </SVGContainer>
              <CardFooter
                toggleFullscreen={() => this.toggleFullscreen}
                fullscreen={fullscreen}
                top={top}
                height={height}
              />
              <Transition
                native
                from={{ height: "133", opacity: "0" }}
                enter={{ height: "0", opacity: "1", custom: height, top: top }}
                leave={{ height: "133", opacity: "0" }}
              >
                {showActionPanel === true &&
                  (({ height, opacity, custom }) => {
                    console.log("trans", custom, top);
                    return (
                      <animated.div
                        style={{
                          opacity: opacity.interpolate((opacity) => opacity),
                          transform: height.interpolate(
                            (height) => `translate3d(0,${height}px,0)`
                          ),
                          transition: "all .05s ease",
                        }}
                      >
                        <ActionPanel
                          custom={custom}
                          closeActionPanel={this.closeActionPanel}
                          currentSelected={details}
                        />
                      </animated.div>
                    );
                  })}
              </Transition>
            </Fragment>
          )}
        </ContainerDimensions>
      </ContainerComponent>
    );
  }
}
