import React, { Component } from "react";

import SettingsPanel from "./SettingsPanel";
import HelpPanel from "./HelpPanel";
import { Button } from "../../Buttons";

import styled from "styled-components";
import { Transition, animated } from "react-spring";

const BottomLabelStyle = styled.div`
  background: #fff;
  position: relative;
  z-index: 5;
  padding: 10px;
  font-size: 1.2rem;
  color: #888;
  text-align: center;
  border: 1px solid #eee;
  /*
    @media (min-width: 540px) {
        font-size: 22px;
    }

    @media (min-width: 640px) {
        padding: 20px;
    }  
    */
`;

const VizFooterHeight = 105;
const VizFooter = styled.div`
  height: ${VizFooterHeight}px;
  position: relative;
  z-index: 6;
  // margin-top:auto;
  flex-shrink: 0;
  flex-grow: 0;
`;

//Card Footer
const VizControls = styled.div`
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(240, 240, 240, 1) 1%,
    rgba(248, 248, 248, 1) 10%,
    rgba(255, 255, 255, 1) 64%
  );
  z-index: 5;
  padding: 15px;
  color: #dcdcdc;
  display: flex;
  justify-content: space-around;
  border-bottom: 5px solid #999;

  @media (min-width: 640px) {
    padding: 15px 20px;
  }
`;

const xAxisLabel = "Historical Account Data";

export default class CardFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelpPanel: false,
      showSettingsPanel: false,
    };
  }

  toggleSettingsPanel = () => {
    this.setState({ showSettingsPanel: !this.state.showSettingsPanel });
  };

  closeSettingsPanel = () => {
    this.setState({ showSettingsPanel: false });
  };

  toggleHelpPanel = () => {
    this.setState({ showHelpPanel: !this.state.showHelpPanel });
  };

  closeHelpPanel = () => {
    this.setState({ showHelpPanel: false });
  };

  render() {
    const { ...props } = this.props;
    const { showSettingsPanel, showHelpPanel } = this.state;
    return (
      <VizFooter>
        <BottomLabelStyle>{xAxisLabel}</BottomLabelStyle>

        <VizControls>
          <Button style={{ color: "#888" }} onClick={props.toggleFullscreen()}>
            <span
              className={`icon dripicons-${
                props.fullscreen ? "contract-2" : "expand-2"
              }`}
            />
          </Button>
          |
          <Button style={{ color: "#888" }} onClick={this.toggleSettingsPanel}>
            <span className="icon dripicons-gear" />
          </Button>
          |
          <Button style={{ color: "#888" }} onClick={this.toggleHelpPanel}>
            <span className="icon dripicons-question" />
          </Button>
        </VizControls>
        <Transition
          native
          from={{ height: "133", opacity: "0" }}
          enter={{
            height: "0",
            opacity: "1",
            custom: props.height,
            top: props.top,
          }}
          leave={{ height: "133", opacity: "0" }}
        >
          {showSettingsPanel &&
            (({ height, opacity, custom }) => {
              console.log("trans", custom, props.top);
              return (
                <animated.div
                  style={{
                    opacity: opacity.interpolate((opacity) => opacity),
                    transform: height.interpolate(
                      (height) => `translate3d(0,${height}px,0)`
                    ),
                    transition: "all .05s ease",
                  }}
                >
                  <SettingsPanel
                    closeSettingsPanel={this.closeSettingsPanel}
                    custom={custom}
                  />
                </animated.div>
              );
            })}
        </Transition>
        <Transition
          native
          from={{ height: "133", opacity: "0" }}
          enter={{
            height: "0",
            opacity: "1",
            custom: props.height,
            top: props.top,
          }}
          leave={{ height: "133", opacity: "0" }}
        >
          {showHelpPanel &&
            (({ height, opacity, custom }) => {
              console.log("trans", custom, props.top);
              return (
                <animated.div
                  style={{
                    opacity: opacity.interpolate((opacity) => opacity),
                    transform: height.interpolate(
                      (height) => `translate3d(0,${height}px,0)`
                    ),
                    transition: "all .05s ease",
                  }}
                >
                  <HelpPanel
                    closeHelpPanel={this.closeHelpPanel}
                    custom={custom}
                  />
                </animated.div>
              );
            })}
        </Transition>
      </VizFooter>
    );
  }
}
