import React, { Component } from "react";

import {
  FlexRow,
  PanelContainer,
  PanelHeader,
  PanelHeading,
} from "../../StyledComponents";
import { Button, CloseIcon } from "../../Buttons";

import styled from "styled-components";

const HelpWrapper = styled(PanelContainer)`
  z-index: 9;
`;

export default class HelpPanel extends Component {
  render() {
    const { custom, closeHelpPanel } = this.props;

    return (
      <HelpWrapper style={{ height: custom._value + 5 }}>
        <PanelHeader>
          <PanelHeading>Help</PanelHeading>
          <Button style={{ color: "#fff" }} onClick={closeHelpPanel}>
            <CloseIcon data-icon="9" className="icon" />
          </Button>
        </PanelHeader>
        <FlexRow>
          <div
            style={{
              padding: "20px",
              height: custom._value - 165,
              overflowY: "auto",
            }}
          >
            Here is the Help panel
          </div>
        </FlexRow>
      </HelpWrapper>
    );
  }
}
