import styled, {css} from 'styled-components';

//Universal
export const Button = styled.button`
    color: #fff;
    line-height: 0;
    padding: 0px;
    border-radius: 4px;
`;

export const CloseIcon = styled.span`
    font-size: 1.4rem;
`;

//Button css for both Details panel and Action Panel
const buttonCss = css`
    border-radius: 50px;
    font-size:0.9rem;
    padding: 7px 14px 6px;
    
    @media (min-width: 640px) {
       
        padding: 9px 16px 8px;
    }  
`;
const marginCss = css`
    margin-right:5px;
    margin-bottom:5px;
`; 
export const ActionButton = styled.button`
    ${buttonCss}
    background: #48afdc;
    color: #fff;
    box-shadow: 0px 2px 28px -9px rgba(0,0,0,0.8);
`;

const ResponseButton = styled.button`
    ${buttonCss}
    ${marginCss}
    border: 1px solid #48afdc
    color: #fff;
`;

export const ActionWrongNumberNoAnswer = styled(ResponseButton)`
    background: #fff;
    border-color: #ff0000;
    color: #ff0000;
`;

export const ActionCallBack = styled(ResponseButton)`
    background: #48afdc;
    border-color: #48afdc;
`;

export const ActionNotInterested = styled(ResponseButton)`
    background: #2b394e;
    border-color: #2b394e;
`;

export const ActionInterested = styled(ResponseButton)`
    background: #28a745;
    border-color: #28a745;
`;
