import React from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
/*const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <HiOutlineChevronDown />
      </components.DropdownIndicator>
    )
  );
};*/

const DropDownSelect = ({
  name,
  options,
  selectedOption,
  onChangeHandler,
  placeholder,
  clear,
  labelFormater,
}) => {
  let defaultValue = {
    label: labelFormater ? labelFormater(selectedOption) : selectedOption,
    value: selectedOption,
  };
  return (
    <Select
      // components={{ DropdownIndicator }}
      clearable={false}
      defaultValue={selectedOption ? defaultValue : null}
      name={name}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      options={options}
      placeholder={placeholder ? placeholder : "Select..."}
      onChange={onChangeHandler}
      value={
        defaultValue.label
          ? { label: defaultValue.label, value: defaultValue.value }
          : null
      }
    />
  );
};
export default DropDownSelect;

export const dropDownStyles = {
  container: (provided, state) => ({
    ...provided,
    display: "inline-block",
    marginRight: "3px",
    width: "100%",
    maxWidth: "190px",
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: "#d6dfe4",
    "&:hover": { borderColor: "#d6dfe4" },
    height: "34px",
    minHeight: "34px",
    boxShadow: state.isFocused ? "0 0 0 1px #d6dfe4" : "none",
    borderRadius: "3px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "34px",
    padding: "0 6px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "0.8rem",
    color: "#375e81",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,

    height: "34px",
    marginTop: "-2px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#cbd4da",
    fontSize: "0.9rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#f6727f" : "#fff",
    "&:hover": { backgroundColor: state.isSelected ? "#f6727f" : "#f8f8f8" },
    fontSize: "0.8rem",
  }),
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    top: "4px",
    zIndex: 999,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "0.8rem",
    color: "#375e81",
  }),
};
