import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";

import BubbleChart from "./BubbleChart";
import BubbleChartBackground from "./BubbleChartBackground";
import TreeBranch from "./TreeBranch";
import BubblesForceLayout from "./BubblesForceLayout";
//import CardLoader from "../CardLoader";
import * as d3 from "d3";

//Tree of Opportunity Specific
const sharedStyle = css`
  font-size: 13px;
  line-height: 1;
`;
const LeftLabelStyle = styled.div`
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 13px;

  color: #999;

  @media (min-width: 540px) {
    font-size: 16px;
  }

  @media (min-width: 992px) {
    font-size: 18px;
  }
`;
const VeryHighLabel = styled.div`
  ${sharedStyle} color: #91d85d;
`;
const VeryLowLabel = styled.div`
  ${sharedStyle} color: #ccc;
`;
const LeftColumnLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px 25px;
  @media (min-width: 640px) {
    padding: 20px 30px;
  }
`;
export default class Tree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      center1: { x: 0, y: 0 },
      center2: { x: 0, y: 0 },
      center3: { x: 0, y: 0 },
      center4: { x: 0, y: 0 },
      center5: { x: 0, y: 0 },
    };
  }

  componentDidMount() {
    this.positionElements();
  }
  componentDidUpdate(prev, next) {
    //console.log("update", prev, next);
    // this.positionElements();
  }

  positionElements = () => {
    const { width, height } = this.props;
    //Do some math for BubbleChartBackground
    // let svgBox = d3.select('#BubbleChart').node().getBoundingClientRect();
    let canvasWidth = width;
    let canvasHeight = height;
    let canvasCenter = canvasWidth / 2;
    //let canvasCenterHor = canvasHeight /2;

    let branch2 = d3.select("#pos2");
    let branch3 = d3.select("#pos3");
    let branch4 = d3.select("#pos4");
    let branch5 = d3.select("#pos5");
    let branch6 = d3.select("#pos6");

    let bg = d3.select("#tree");
    let bgInfo = d3
      .select("#tree")
      .node()
      .getBBox();
    //console.log("bginfo", bgInfo);
    let bgCenter = bgInfo.width / 2;
    let bgNewX = canvasCenter - bgCenter;
    let fromTop = canvasHeight - bgInfo.height;
    let bgNewY = -(canvasHeight - bgInfo.height);
    bgNewY = -(bgInfo.height + bgNewY);

    //center and place at bottom of canvas
    bg.attr("transform", "translate(" + bgNewX + "," + bgNewY + ")")
      .transition()
      .duration(500)
      .attr("transform", "translate(" + bgNewX + "," + bgNewY + ")");

    branch2
      .style("opacity", 0)
      .transition()
      .delay(100)
      .style("opacity", 1.0);
    branch3
      .style("opacity", 0)
      .transition()
      .delay(300)
      .style("opacity", 1.0);
    branch4
      .style("opacity", 0)
      .transition()
      .delay(600)
      .style("opacity", 1.0);
    branch5
      .style("opacity", 0)
      .transition()
      .delay(800)
      .style("opacity", 1.0);
    branch6
      .style("opacity", 0)
      .transition()
      .delay(1000)
      .style("opacity", 1.0);

    //set center  positions for bubble clusters on tree
    this.setState((state) => {
      return {
        //width: svgBox.width,
        // height: svgBox.height,
        center1: { x: bgNewX, y: fromTop },
        center2: { x: bgNewX + 125, y: fromTop + 17 },
        center3: { x: bgNewX - 50, y: fromTop + 95 },
        center4: { x: bgNewX + 160, y: fromTop + 130 },
        center5: { x: bgNewX - 60, y: fromTop + 205 },
      };
    });
  };

  render() {
    const svgPath =
      "M0,400h10.794l85,241.508l44.762,158.453H26.793l26-158.453L0,400z"; //'M0,0h10.794l85,241.547L140.555,400H26.793l26-158.453L0,0z';
    const svgBranchPathLeft =
      "M0,6.919C0,2.373,0,0,0,0l120,39.483c0,0,0,22.902,0,30.178C109.98,59.881,52.604,20.994,0,6.919z";
    const svgBranchPathRight =
      "M0,69.661c0-7.275,0-30.178,0-30.178L120,0c0,0,0,2.373,0,6.919C67.396,20.994,10.02,59.881,0,69.661z";
    const svgBranchStubRight =
      "M35.937,11.081C12.103,21.882,6.186,55.456,5.832,69.661c0,0-3.083-22.83-5.832-46.163C14.918,0.165,34.54,0,34.54,0S34.902,2.442,35.937,11.081z";
    //const svgBranchStubLeft = 'M1.396,0c0,0,19.621,0.165,34.54,23.498c-2.748,23.333-5.831,46.163-5.831,46.163C29.751,55.456,23.834,21.882,0,11.081C1.034,2.442,1.396,0,1.396,0z';
    const { center1, center2, center3, center4, center5 } = this.state;

    const {
      openDetails,
      width,
      height,
      error,
      fullscreen,
      yAxisLabel,
      selectedSalesPerson,
      isLoading,
      ...props
    } = this.props;
    // console.log("tree", selectedSalesPerson);

    return (
      /* THIS IS leftside label vertical text*/
      <div
        className="row"
        style={{
          border: "0px solid red",
          margin: "0",
          justifyContent: "center",
        }}
      >
        <div className="col-1 col-md-2">
          <LeftColumnLabels>
            <VeryHighLabel>Highest</VeryHighLabel>
            <LeftLabelStyle>{yAxisLabel}</LeftLabelStyle>
            <VeryLowLabel>Lowest</VeryLowLabel>
          </LeftColumnLabels>
        </div>
        <div className="col-10 col-md-9">
          <BubbleChart width={width} height={height}>
            <BubbleChartBackground id="tree" d={svgPath} />

            <TreeBranch
              id="pos2"
              transform="translate(210,128)  rotate(-7)"
              className="branch"
              d={svgBranchPathRight}
            />
            <TreeBranch
              id="pos3"
              transform="translate(130,185) scale(.7)"
              className="branch"
              d={svgBranchPathLeft}
            />
            <TreeBranch
              id="pos4"
              transform="translate(235,238)  rotate(-10)"
              className="branch"
              d={svgBranchPathRight}
            />
            <TreeBranch
              id="pos5"
              transform="translate(115,295)  rotate(-10)"
              className="branch"
              d={svgBranchPathLeft}
            />
            <TreeBranch
              id="pos6"
              transform="translate(293,355)  rotate(14)"
              className="branch"
              d={svgBranchStubRight}
            />

            <BubblesForceLayout
              fullscreen={fullscreen}
              id="bubble1"
              openDetails={openDetails}
              position1={center1}
              label="Data Center"
              data={props.data1}
              forceStrength={0.03}
              center={center1}
            />

            <BubblesForceLayout
              fullscreen={fullscreen}
              id="bubble2"
              openDetails={openDetails}
              position1={center1}
              label="Campus"
              data={props.data2}
              forceStrength={0.03}
              center={center2}
            />

            <BubblesForceLayout
              fullscreen={fullscreen}
              id="bubble3"
              openDetails={openDetails}
              position1={center1}
              label="Smart Edge"
              data={props.data3}
              forceStrength={0.03}
              center={center3}
            />
            <BubblesForceLayout
              fullscreen={fullscreen}
              id="bubble4"
              openDetails={openDetails}
              position1={center1}
              label="Core Switches"
              data={props.data4}
              forceStrength={0.03}
              center={center4}
            />

            <BubblesForceLayout
              fullscreen={fullscreen}
              id="bubble5"
              openDetails={openDetails}
              position1={center1}
              label="Next-Gen Firewall"
              data={props.data5}
              forceStrength={0.03}
              center={center5}
            />

            {error ? (
              <Fragment>
                <rect
                  width="100"
                  height="50"
                  x={width / 2 - 70}
                  y={height / 2 - 30}
                  style={{ fill: "#fff" }}
                />
                <text
                  style={{ opacity: 1, fill: "#999" }}
                  dx={width / 2 - 50}
                  dy={height / 2}
                >
                  {error}
                </text>
              </Fragment>
            ) : null}
          </BubbleChart>
        </div>
      </div>
    );
  }
}
