import React from "react";
import ContentLoader from "react-content-loader";

//Container for waiting for parent renders
const CardLoader = (props) => (
  <ContentLoader
    height={155}
    width={400}
    speed={2}
    primaryColor="#eeeeee"
    secondaryColor="#aaaaaa"
    {...props}
  >
    <rect x="174.5" y="38.05" rx="0" ry="0" width="75" height="10" />
    <rect x="174.5" y="55.05" rx="0" ry="0" width="75" height="10" />
    <rect x="174.5" y="72.05" rx="0" ry="0" width="75" height="10" />
  </ContentLoader>
);
export default CardLoader;
