import React, { Component } from "react";

import CardLoader from "./CardLoader";
import { Button, ActionButton, CloseIcon } from "../../Buttons";
import {
  FlexRow,
  Fixedspan,
  PanelContainer,
  PanelHeader,
  PanelHeading,
  TruncatedSpan,
  PaddedDiv20,
} from "../../StyledComponents";

import styled from "styled-components";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "./sliderstyles.css";

const DetailsWrapper = styled(PanelContainer)`
  bottom: 1rem;
  font-size: 0.8rem;
  @media (min-width: 640px) {
    bottom: 1.6rem;
    font-size: 1rem;
  }
`;

export default class DetailsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      currentSelected: this.props.currentSelected,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.currentSelected !== prevProps.currentSelected) {
      this.setState({
        data: this.props.data,
        currentSelected: this.props.currentSelected,
      });
    }
  };

  render() {
    if (this.state === null || this.state.currentSelected === undefined) {
      return <CardLoader />;
    }
    const {
      currentSelected,
      data: { children: data },
    } = this.state;
    const {
      fullscreen,
      closeSelf,
      openActionPanel,
      changeSelected,
    } = this.props;
    const currentSelectedIndex = data.findIndex(
      (obj) => obj.id === currentSelected.id
    );

    const handleChange = (slide) => {
      let newDetails = data[slide.currentIndex];
      changeSelected(newDetails);
    };

    return (
      <DetailsWrapper fullscreen={fullscreen}>
        <PanelHeader>
          <PanelHeading>{currentSelected.campaign}</PanelHeading>
          <Button style={{ color: "#fff" }} onClick={closeSelf}>
            <CloseIcon data-icon="9" className="icon" />
          </Button>
        </PanelHeader>
        <FlexRow>
          <AwesomeSlider
            selected={currentSelectedIndex}
            onTransitionEnd={handleChange}
          >
            {data.map((dataObj, key) => (
              <div key={dataObj.campaign + key} style={{ fontSize: "0.9rem" }}>
                <div>
                  <Fixedspan>Account Domain:</Fixedspan>{" "}
                  <TruncatedSpan title={dataObj.domain}>
                    {dataObj.domain}
                  </TruncatedSpan>
                </div>
                <div>
                  <Fixedspan>Hot Topic:</Fixedspan>{" "}
                  <TruncatedSpan title={dataObj.topic_name}>
                    {dataObj.topic_name}
                  </TruncatedSpan>
                </div>
                <div>
                  <Fixedspan>Score:</Fixedspan>{" "}
                  <TruncatedSpan title={dataObj.score}>
                    {" "}
                    {dataObj.score}
                  </TruncatedSpan>
                </div>
              </div>
            ))}
          </AwesomeSlider>
        </FlexRow>
        <FlexRow>
          <PaddedDiv20>
            <ActionButton onClick={(e) => openActionPanel(e, currentSelected)}>
              Take Action
            </ActionButton>
          </PaddedDiv20>
        </FlexRow>
      </DetailsWrapper>
    );
  }
}
