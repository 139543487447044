//import React from 'react';
//import request from 'superagent';
//import nocache from 'superagent-no-cache';
//import fs from 'fs';

//CSV parser
const Papa = window.Papa;

const capitalize = (string) => {
  return string.replace(/(^|\s)([a-z])/g, function(m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
};

export const getTableData = (csvFile, callBack, shouldCancelCallback) => {
  const tableRows = [];
  const columns = [];
  let fields = [];

  try {
    Papa.parse(csvFile, {
      download: true,
      delimiter: ",",
      header: true,
      skipEmptyLines: true,
      preview: 1500,
      worker: true,
      step: (results, parser) => {
        if (shouldCancelCallback && shouldCancelCallback()) {
          parser.abort();
          callBack(null, null);
        }

        if (fields.length === 0) fields = results.meta.fields;

        const data = results.data;

        for (let x = 0; x < data.length; x++) {
          //if(x===0)
          //console.log('record', results);

          if (results.data[x] !== undefined) tableRows.push(results.data[x]);
        }
        //console.log('tableRows',tableRows);
      },
      error: (results, err) => {
        console.log("error", results, err);
      },
      complete: (results) => {
        let minWidth = "";

        //console.log('fields',fields);

        if (fields.length < tableRows.length) {
          for (let i = 0; i < fields.length; i++) {
            minWidth = getTextWidth(fields[i], tableRows[i][fields[i]]);

            columns.push({
              headerText: capitalize(fields[i].replace(/_/g, " ")),
              accessor: fields[i],
              minWidth:
                minWidth +
                (csvFile !== "globalCampaignsSummary.csv" &&
                csvFile !== "topPerformingCampaigns.csv"
                  ? 34
                  : 0),
            });
          }
        } else {
          for (let i = 0; i < fields.length; i++) {
            if (fields[i] === "Total Revenue") minWidth = 80;
            else if (fields[i] === "ROI (MQL)" || fields[i] === "ROI (SQL)") {
              minWidth = 60;
            } else minWidth = 95;

            columns.push({
              headerText: capitalize(fields[i].replace(/_/g, " ")),
              accessor: fields[i],
              minWidth:
                minWidth +
                (csvFile !== "globalCampaignsSummary.csv" &&
                csvFile !== "topPerformingCampaigns.csv"
                  ? 34
                  : 0),
            });
          }
        }
        //console.log('tableRows after',tableRows);
        callBack({ tableRows, columns });
      },
    });
  } catch (e) {
    console.log("CSVUTILS ERROR", e);
  }
};
export const getTextWidth = (headerField, col_data) => {
  col_data = col_data.toString();
  const maxWidth = 350;

  let colWidth = 0;
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  ctx.font = "16px Arial";

  //Use length of header text if it is longer than the data text
  if (headerField.trim().length > col_data.trim().length) {
    colWidth = ctx.measureText(headerField).width;
    return colWidth + 30;
  }

  colWidth = ctx.measureText(col_data).width;
  return Math.min(colWidth + 30, maxWidth);
};

export const downloadResultsCSV = (filterResults, e) => {
  let event = e;
  const results = filterResults;

  event.preventDefault();

  let CSV;
  let fileName = "filterResults.csv";

  CSV = Papa.unparse(results);

  let blob = new Blob([CSV], {
    type: "text/csv;charset=utf8;" || "application/octet-stream",
  });

  // Create hidden link
  let element = document.createElement("a");
  document.body.appendChild(element);

  element.setAttribute("href", window.URL.createObjectURL(blob));
  element.setAttribute("download", fileName);
  element.style.display = "";

  element.click();

  document.body.removeChild(element);
  event.stopPropagation();
};
