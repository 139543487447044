import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Fixedspan = styled.span`
  display: inline-block;
  width: 120px;
  text-align: right;
`;

export const TruncatedSpan = styled.span`
  max-width: 135px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  display: inline-block;
  padding-left: 6px;
`;

export const PaddedDiv20 = styled.div`
  padding: 0 0 25px;
  margin: auto;
`;

//Both Details Panel and action Panel
export const PanelContainer = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
`;

export const PanelHeader = styled.div`
  align-items: center;
  background: #555;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  width: 100%;
`;

export const PanelHeading = styled.h4`
  margin: 0;
  color: #fff;
  font-size: 1.2rem;
`;
