import React, { Component } from "react";

import {
  FlexRow,
  PanelContainer,
  PanelHeader,
  PanelHeading,
} from "../../StyledComponents";
import * as Table from "../../Table";
import {
  Button,
  ActionCallBack,
  ActionInterested,
  ActionNotInterested,
  ActionWrongNumberNoAnswer,
  CloseIcon,
} from "../../Buttons";

import styled from "styled-components";
import GeminiScrollbar from "react-gemini-scrollbar";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "./sliderstyles.css";

//Action Panel css components
const ActionContainer = styled(PanelContainer)`
  overflow: hidden;
  padding: 0;
  z-index: 3;
`;
const ActionPanelOutCome = styled.div`
  padding: 15px;
  padding-top: 0;
`;

const NotesOutCome = styled.form`
  padding: 0 15px 15px;
`;

const NotesContainer = styled.div`
  border: 1px solid #eee;
`;
const NotesHeader = styled.div`
  border-bottom: 1px solid #eee;
  padding: 10px;
`;
const NotesBody = styled.textarea`
  padding: 10px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  width: 100%;
`;
const NotesActions = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SaveCancel = styled.button`
  padding: 7px 14px;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 0.9rem;
`;

const CallCount = styled.div`
  color: #48afdc;
  font-size: 1.4rem;
  @media (min-width: 640px) {
    font-size: 1.6rem;
  }
`;

const FormFeedback = styled.small`
  font-size: 0.7rem;
  color: green;
  width: 100%;
  text-align: right;
`;

//Data for Actionpanel slider of contacts
const someContacts = [
  {
    name: "Jen Brooks",
    email: "jen@example.com",
    phone: "444-333-4444",
    account: "IBM IT",
  },
  {
    name: "John Schmidt",
    email: "john@abcd.com",
    phone: "435-234-3245",
    account: "GM Logistics",
  },
  {
    name: "Barb Taylor",
    email: "barb@music.com",
    phone: "414-999-1234",
    account: "Verizon Wireless",
  },
  {
    name: "Dieter Fischer",
    email: "hello@furniture.com",
    phone: "514-456-1124",
    account: "Furniture.com",
  },
];

export default class ActionPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      currentContact: 0,
      notes: this.fetchNotes(),
      id: this.props.currentSelected.id,
    };
  }

  fetchNotes = () => {
    let notes = JSON.parse(
      localStorage.getItem(`notes-[${this.props.currentSelected.id}]`)
    );
    if (!notes) return (notes = Object.assign({}, someContacts));
    return notes;
  };

  handleCallOutcome = (type) => {
    const { notes, currentContact } = this.state;
    let outcome = (notes[currentContact].outcome = type);
    this.setState({ outcome });
  };

  handleCurrentContact = (slide) => {
    this.setState({ currentContact: slide.currentIndex });
  };

  handleNotesBody = (event) => {
    const { notes, currentContact } = this.state;
    let bodyText = event.target.value;
    let body = (notes[currentContact].body = bodyText);
    this.setState({ body });
  };

  handleSave = (event) => {
    this.setState({ saving: true });
    event.preventDefault();
    localStorage.setItem(
      `notes-[${this.state.id}]`,
      JSON.stringify(this.state.notes)
    );
    this.setState({ saving: false, saved: true });
    setTimeout(() => {
      this.setState({ saved: false });
    }, 5000);
  };

  getOutcomeButton = () => {
    let component;
    const { notes, currentContact } = this.state;
    let comparison = notes[currentContact].outcome;
    switch (comparison) {
      case "noAnswer":
        component = (
          <ActionWrongNumberNoAnswer>No Answer</ActionWrongNumberNoAnswer>
        );
        break;
      case "wrongNumber":
        component = (
          <ActionWrongNumberNoAnswer>Wrong Number</ActionWrongNumberNoAnswer>
        );
        break;
      case "callBack":
        component = <ActionCallBack> Call Back </ActionCallBack>;
        break;
      case "notInterested":
        component = <ActionNotInterested> Not Interested </ActionNotInterested>;
        break;
      case "interested":
        component = <ActionInterested> Interested </ActionInterested>;
        break;
      default:
        break;
    }
    return component;
  };

  render() {
    const notesbodytext = "Enter notes from calls";
    const { notes, currentContact, saving } = this.state;
    const { custom, closeActionPanel, currentSelected } = this.props;

    return (
      <ActionContainer style={{ height: custom._value }}>
        <PanelHeader>
          <PanelHeading style={{ marginBottom: 0, color: "#fff" }}>
            Actions: {currentSelected.domain}
          </PanelHeading>
          <Button onClick={closeActionPanel}>
            <CloseIcon data-icon="9" className="icon" />
          </Button>
        </PanelHeader>

        <div style={{ height: custom._value - 165, overflowY: "auto" }}>
          <GeminiScrollbar autoshow={true} ref="gemini-notes">
            <FlexRow>
              <div style={{ width: "100%" }}>
                <Table.Table border="1px solid #ddd">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <CallCount>49</CallCount> Recommended
                      </Table.Cell>
                      <Table.Cell>
                        <CallCount>23</CallCount> Processed
                      </Table.Cell>
                      <Table.Cell>
                        <CallCount>19</CallCount> Unprocessed
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table.Table>
              </div>
            </FlexRow>

            <FlexRow>
              <AwesomeSlider onTransitionEnd={this.handleCurrentContact}>
                {someContacts.map((contact, index) => (
                  <div key={index} style={{ fontSize: "0.9rem" }}>
                    <div>
                      <span
                        style={{
                          width: "50px",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      >
                        Contact
                      </span>
                      <a
                        style={{ color: "#48afdc" }}
                        href={`mailto:${contact.email}`}
                      >
                        {contact.name}
                      </a>
                    </div>
                    <div>
                      <span
                        style={{
                          width: "50px",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      >
                        Phone
                      </span>
                      <a
                        style={{ color: "#48afdc" }}
                        href={`tel:${contact.phone}`}
                      >
                        {contact.phone}
                      </a>
                    </div>
                    <div>
                      <span
                        style={{
                          width: "50px",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      >
                        Account
                      </span>
                      <a href="#none" style={{ color: "#48afdc" }}>
                        {contact.account}
                      </a>
                    </div>
                  </div>
                ))}
              </AwesomeSlider>
            </FlexRow>

            <FlexRow>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderTop: "1px solid #eee",
                  padding: "10px",
                  fontSize: "0.9rem",
                }}
              >
                Please select the outcome of this call
              </div>
            </FlexRow>

            <FlexRow style={{ flexDirection: "column" }}>
              <ActionPanelOutCome>
                <ActionWrongNumberNoAnswer
                  onClick={() => this.handleCallOutcome("noAnswer")}
                >
                  No Answer
                </ActionWrongNumberNoAnswer>
                <ActionWrongNumberNoAnswer
                  onClick={() => this.handleCallOutcome("wrongNumber")}
                >
                  Wrong Number
                </ActionWrongNumberNoAnswer>
                <ActionCallBack
                  onClick={() => this.handleCallOutcome("callBack")}
                >
                  Call Back
                </ActionCallBack>
                <ActionNotInterested
                  onClick={() => this.handleCallOutcome("notInterested")}
                >
                  Not Interested
                </ActionNotInterested>
                <ActionInterested
                  onClick={() => this.handleCallOutcome("interested")}
                >
                  Interested
                </ActionInterested>
              </ActionPanelOutCome>
              <NotesOutCome onSubmit={(e) => this.handleSave(e)}>
                Notes
                <NotesContainer>
                  {notes[currentContact].outcome && (
                    <NotesHeader>{this.getOutcomeButton()}</NotesHeader>
                  )}
                  <NotesBody
                    onChange={(e) => this.handleNotesBody(e)}
                    placeholder={notesbodytext}
                    value={notes[currentContact].body || ""}
                  />
                </NotesContainer>
                <NotesActions>
                  <SaveCancel type="reset">Cancel</SaveCancel>
                  <SaveCancel
                    type="submit"
                    style={{ borderColor: "#48afdc", color: "#48afdc" }}
                    disabled={saving}
                  >
                    Save
                  </SaveCancel>
                  {this.state.saved ? (
                    <FormFeedback>Notes Saved!</FormFeedback>
                  ) : (
                    ""
                  )}
                </NotesActions>
              </NotesOutCome>
            </FlexRow>
          </GeminiScrollbar>
        </div>
      </ActionContainer>
    );
  }
}
