import React from 'react';
import PropTypes from 'prop-types';
//import * as d3 from 'd3';
import  '../../viz.css';



export default function BubbleChart({ className, width, height, children }) {
   let viewBoxDim = `0 0 510 510`;
    return (
        <svg id="BubbleChart" style={{padding: '0 15px'}} className={className}  width="100%" height="100%" viewBox={viewBoxDim}>

          <defs>
          <pattern id="hash4_4" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
              <rect width="2" height="4" transform="translate(0,0)" fill="red"></rect>
          </pattern>
          </defs>

          {
            children
          }

        </svg>
      )

}

BubbleChart.propTypes = {
    className: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    children: PropTypes.node,
  }
BubbleChart.defaultProps = {
 className: 'bubbleChart'
};  