import styled from 'styled-components';

export const Table = styled.table`
    width:100%;
    border: ${props => props.border};
`;

export const Body = styled.tbody``;

export const Row = styled.tr``;

export const Cell = styled.td`
    border-bottom:1px solid #eee;
    border-right: 1px solid #eee;
    vertical-align: middle;
    text-align: center;
    padding:10px;
    font-size: 0.8rem;
    @media (min-width: 640px) {
        padding:15px;
    }    
`;