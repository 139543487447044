import React, { Component } from 'react'

import { Button, CloseIcon } from '../../Buttons';
import * as Table from '../../Table';
import { FlexRow, PanelContainer, PanelHeader, PanelHeading } from '../../StyledComponents';

import styled from "styled-components";

const SettingsWrapper = styled(PanelContainer)`
    z-index: 9;
`;

const SettingsTd = styled(Table.Cell)`
    font-size: 1rem;
    text-align: left;
    border-right: none;
`;

export default class SettingsPanel extends Component {
  render() {
      const { custom, closeSettingsPanel } = this.props
    return (
      <SettingsWrapper style={{height: custom._value + 5}}>
            <PanelHeader>
                <PanelHeading>Settings</PanelHeading>
                <Button style={{color: '#fff'}} onClick={closeSettingsPanel}>
                        <CloseIcon data-icon="9" className="icon"></CloseIcon>
                </Button>   
            </PanelHeader>
            <FlexRow>
                <div style={{height: custom._value - 165, overflowY: 'auto', width: "100%"}}>
                    <Table.Table border="none">
                        <Table.Body>
                            <Table.Row>
                                <SettingsTd>Profile</SettingsTd>
                            </Table.Row>
                            <Table.Row>
                                <SettingsTd>Preferences</SettingsTd>
                            </Table.Row>
                            <Table.Row>
                                <SettingsTd>About</SettingsTd>
                            </Table.Row>
                        </Table.Body>
                    </Table.Table>
                </div>
            </FlexRow>
      </SettingsWrapper>
    )
  }
}
