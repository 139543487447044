import React from 'react';
import PropTypes from 'prop-types';


const TreeBranch = ({ id,className, d, transform }) =>{
    return(
        <g id={id} transform={transform} className={className}>
            <path d={d} />
        </g>    
    );
}
export default TreeBranch;

TreeBranch.propTypes = {
    className: PropTypes.string.isRequired,
    d: PropTypes.string.isRequired
}